import React, { useEffect, useRef, useState } from 'react'
import LoaderSpinner from '../loader-spinner'

function CaseStudySectionHeader(props) {
	const data = props.data.header
	const bgRef = useRef()
	const [loading, setLoading] = useState(true)
	const [count, setCount] = useState(0)
	const [style, setStyle] = useState({ visibility: 'hidden' })

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	function handleScroll(event) {
		var doc = event.target
		var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
		let itemTranslate = top / 2.2 || 0
		bgRef.current.style.transform = `translateY(${itemTranslate}px)`
	}

	function handleLoadComplete(event) {
		const _count = count + 1
		setCount(_count)
		if (_count >= 2) {
			setLoading(false)
			setStyle({ visibility: 'visible' })
		}
	}

	return (
		<div className="header-section">
			<div style={style}>
				<div className="cover-image" ref={bgRef}>
					<img src={data.bg} alt="cover-image" onLoad={handleLoadComplete} />
				</div>
				<div className="title-container">
					<img src={data.logo} alt="header-logo" onLoad={handleLoadComplete} />
				</div>
				{/* <div className="more-button">
				<div>icon arrow</div>
			</div> */}
			</div>
			{loading && <LoaderSpinner />}
		</div>
	)
}

export default CaseStudySectionHeader
