import React from 'react'
import './loader-spinner.scss'

function LoaderSpinner() {
	const pool = []
	for (var i = 0; i < 6; i++) {
		pool.push(<div className={'bar' + (i + 1)} key={i} />)
	}
	return <div className="loader-spinner">{pool}</div>
}

export default LoaderSpinner
