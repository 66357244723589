import axios from 'axios'
import { useEffect, useState } from 'react'

export const useFetch = url => {
	const [state, setState] = useState({ data: null, isLoading: true })
	useEffect(() => {
		setState(state => ({ data: state.data, isLoading: true }))
		axios.get(url).then(response => {
			setState({ data: response.data, isLoading: false })
		})
	}, [url])

	return state
}
